<template lang="pug">
  .invoice
    project-facade(v-if="invoice", active-tab="invoice", :project-id="invoice.project.id")
    invoice-form(
      v-model='invoice',
      :loading='$apollo.loading',
      :saving='saving',
      submit-label='Update invoice',
      @save='updateInvoice',
      @cancel='cancelUpdate')
</template>

<script>
import updateInvoiceMutation from "@/graphql/mutations/finance/invoices/UpdateInvoice.gql";
import updateInvoiceFilter from "@/graphql/mutations/filters/UpdateInvoiceFilter";
import invoiceQuery from "@/graphql/queries/finance/Invoice.gql";
import mutate from "@/components/utils/Mutator";
import InvoiceForm from "./Form.vue";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    invoice: {
      query: invoiceQuery,
      variables() {
        return { id: this.$route.params.id };
      }
    }
  },
  components: {
    InvoiceForm,
    ProjectFacade
  },
  data() {
    return {
      invoice: null,
      saving: false
    };
  },
  methods: {
    updateInvoice() {
      const { project } = this.invoice;
      const vm = this;

      this.saving = true;

      return mutate(this, {
        mutation: updateInvoiceMutation,
        variables: {
          input: {
            ...updateInvoiceFilter(this.invoice)
          }
        }
      })
        .then(data => {
          window.location.assign(
            `/projects/${project.id}/invoices/${data.data.updateInvoice.invoice.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelUpdate() {
      window.location.assign(
        `/projects/${this.invoice.project.id}/invoices/${this.invoice.id}`
      );
    }
  }
};
</script>
